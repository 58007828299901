import { FormControl } from '@material-ui/core';
import { useAddUserToSites } from 'apollo-hooks';
import { MODULE_ID, PERMISSIONS } from 'appGlobals';
import ErrorDisplay from 'components/ErrorDisplay';
import { CancelButton } from 'components/Shared/CancelButton';
import { FormsButtons } from 'components/Shared/FormsButtons';
import { ProgressButton } from 'components/Shared/ProgressButton';
import React, { useContext, useState } from 'react';
import {
  CreateInvitationInputType,
  GetSiteAdminQueryVariables,
  GetUsersAdminQueryVariables,
} from 'tillr-graphql';
import UserProfileContext from 'UserProfileContext';

interface IProps {
  getSiteAdminQueryVariables?: GetSiteAdminQueryVariables;
  getUsersAdminQueryVariables?: GetUsersAdminQueryVariables;
  siteId: number;
  roleId: string;
  onCancel: () => void;
  onSuccess: () => void;
}

export function AddUserFormContainer(props: IProps) {
  const {
    getSiteAdminQueryVariables,
    getUsersAdminQueryVariables,
    siteId,
    roleId,
    onCancel,
    onSuccess,
  } = props;

  const userProfle = useContext(UserProfileContext)!;
  const isAdmin = userProfle.hasAnyPermission([PERMISSIONS.ADMIN]);

  const [addUserToSites, addUserToSitesState] = useAddUserToSites({
    getSiteAdminQueryVariables,
    getUsersAdminQueryVariables,
  });

  const [email, setEmail] = useState<string>('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.currentTarget.value.trim());
  };

  const handleSubmit = () => {
    const invitation: CreateInvitationInputType = {
      email,
      roleId,
      modules: [MODULE_ID],
      siteIds: [siteId],
      customData: isAdmin ? JSON.stringify({ origin }) : undefined,
    };

    addUserToSites({ variables: { invitation } }).then((response) => {
      if (response.data?.addUserToSites) {
        addUserToSitesState.reset();
        onSuccess();
      }
    });
  };

  return (
    <>
      {addUserToSitesState.error && <ErrorDisplay error={addUserToSitesState.error} />}

      <form onSubmit={(e) => e.preventDefault()}>
        <fieldset>
          <FormControl>
            <label htmlFor="email" className="control-label">
              <span className="required-field">*</span> Email address
            </label>
            <input
              id="email"
              name="email"
              type="text"
              className="form-control"
              value={email}
              onChange={handleChange}
              required
            />
          </FormControl>

          <FormsButtons>
            <CancelButton onClick={onCancel} />
            <ProgressButton
              disabled={!email}
              label="Add user"
              loading={addUserToSitesState.loading}
              onClick={handleSubmit}
            />
          </FormsButtons>
        </fieldset>
      </form>
    </>
  );
}
