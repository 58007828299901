import { PERMISSIONS } from 'appGlobals';
import { AuthenticatedRoute } from 'auth/AuthenticatedRoute';
import { ActivityIndicator } from 'components/ActivityIndicator';
import ErrorDisplay from 'components/ErrorDisplay';
import { PageTitle } from 'components/Shared/PageTitle';
import { getChainPath } from 'paths';
import React, { useContext } from 'react';
import { Redirect, Switch, useParams } from 'react-router';
import { CHAIN_SITE_TYPE, GROUP_SITE_TYPE } from 'types';
import UserProfileContext from 'UserProfileContext';
import { PayPage } from './PayPage';
import { useGetChainFromParams } from './useGetChainFromParams';
import { ChainVenuePremisesInfoAdminPage } from './Venues/ChainVenuePremisesInfoAdminPage';
import { ChainVenuesDetailsPage } from './Venues/ChainVenuesDetailsPage';
import { ChainVenuesManagePage } from './Venues/ChainVenuesManagePage';

export function ChainRoutes() {
  const { action } = useParams<{ action: string }>();
  const { loading, error, chainId, chainSite } = useGetChainFromParams();

  const userProfile = useContext(UserProfileContext)!;

  if (
    !userProfile.hasAnyPermission([PERMISSIONS.ADMIN, PERMISSIONS.VENUES_ADMIN]) &&
    !userProfile.siteIds.includes(chainId)
  ) {
    return (
      <div className="page">
        <ErrorDisplay error="You do not have permission to access this chain." />
      </div>
    );
  }

  if (!action) {
    const isAdmin = userProfile.hasAnyPermission([PERMISSIONS.ADMIN, PERMISSIONS.VENUES_ADMIN]);
    if (isAdmin) {
      // Admins default to manage page
      return <Redirect to={getChainPath(chainId, '/manage')} />;
    }

    const isChainManager = userProfile.hasAnyPermission([PERMISSIONS.CHAIN_MANAGE]);
    if (isChainManager) {
      // Chain manager defaults to details page
      return <Redirect to={getChainPath(chainId, '/details')} />;
    }

    throw new Error('Unauthorised.');
  }

  const siteIsNotChain =
    chainSite && chainSite.type !== CHAIN_SITE_TYPE && chainSite.type !== GROUP_SITE_TYPE;

  if (loading || error || siteIsNotChain) {
    return (
      <>
        <PageTitle title={chainSite?.name ?? 'Manage chain'} />
        <div className="page">
          {loading && <ActivityIndicator showProgressBar />}
          {error && <ErrorDisplay error={error} />}
          {siteIsNotChain && <ErrorDisplay error="This site is not a chain." />}
        </div>
      </>
    );
  }

  return (
    <Switch>
      <AuthenticatedRoute
        exact
        permissions={[PERMISSIONS.ADMIN, PERMISSIONS.CHAIN_MANAGE]}
        path="/chains/:chainId/details"
        component={ChainVenuesDetailsPage}
      />
      <AuthenticatedRoute exact path="/chains/:chainId/manage" component={ChainVenuesManagePage} />
      <AuthenticatedRoute
        exact
        permissions={[PERMISSIONS.ADMIN, PERMISSIONS.VENUES_ADMIN]}
        path="/chains/:chainId/venues/:venueId/premises-info"
        component={ChainVenuePremisesInfoAdminPage}
      />
      <AuthenticatedRoute exact path="/chains/:chainId/pay" component={PayPage} />
    </Switch>
  );
}
