import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { STANDARD_USER_ROLE_ID } from 'appGlobals';
import { AddUserFormContainer } from 'components/Shared/AddUserFormContainer';
import React, { useState } from 'react';

interface IProps {
  siteId: number;
}

export function AddUserButton(props: IProps) {
  const { siteId } = props;

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        startIcon={<PersonAddIcon />}
        variant="contained"
        color="secondary"
        onClick={handleOpen}
      >
        Add user
      </Button>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
        <DialogTitle>Add user</DialogTitle>
        <DialogContent>
          <AddUserFormContainer
            getSiteAdminQueryVariables={{ id: siteId }}
            siteId={siteId}
            roleId={STANDARD_USER_ROLE_ID}
            onCancel={handleClose}
            onSuccess={handleClose}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
