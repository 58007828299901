import SiteContext from 'components/Sites/SiteContext';
import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router';
import UserManagerContext from 'UserManagerContext';
import { AuthScreen } from './AuthScreen';

export function Authenticate(props: RouteComponentProps) {
  const {
    location: { search },
  } = props;

  const userManager = useContext(UserManagerContext)!;
  const { setSiteId } = useContext(SiteContext)!;

  const loginHint = new URLSearchParams(search).get('login_hint');
  const siteId = new URLSearchParams(search).get('s');

  if (siteId) {
    setSiteId(Number(siteId));
  }

  userManager.signinRedirect({ login_hint: loginHint });

  return <AuthScreen state="redirecting" />;
}
