import { Box } from '@material-ui/core';
import { PERMISSIONS } from 'appGlobals';
import { SignOutButton } from 'auth/SignOutButton';
import { ResourcesPanel } from 'components/Resources/ResourcesPanel';
import { PageTitle } from 'components/Shared/PageTitle';
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import UserProfileContext from 'UserProfileContext';
import { getGreeting } from 'utils';
import { DownloadKmz } from './DownloadKmz';

export function AdminPage() {
  const userProfile = useContext(UserProfileContext)!;

  const isAdmin = userProfile.hasAnyPermission([PERMISSIONS.ADMIN]);
  const isVenuesAdmin = userProfile.hasAnyPermission([PERMISSIONS.VENUES_ADMIN]);

  return (
    <>
      <PageTitle title="Admin Dashboard" />
      <div className="journey">
        <div className="col-sm-8 col-xs-12">
          <section>
            <h3>
              <span>{`${getGreeting()} ${userProfile.firstName}`}</span> <SignOutButton />
            </h3>

            {(isAdmin || isVenuesAdmin) && (
              <>
                <div className="alert alert--forms">
                  <h4>Sites</h4>
                  <ul>
                    <li>
                      <Link to="/admin/chains">
                        <i className="glyphicon glyphicon-home"></i> Manage chains
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin/venues">
                        <i className="glyphicon glyphicon-home"></i> Manage venues
                      </Link>
                    </li>
                  </ul>
                </div>
                <hr />

                <div className="alert alert--reports">
                  <h4>Reports</h4>
                  <ul>
                    {isAdmin && (
                      <>
                        <li>
                          <Link to="/admin/reports/activity-report">
                            <i className="glyphicon glyphicon-table"></i> Activity report
                          </Link>
                        </li>
                        <li>
                          <Link to="/admin/reports/approved-applications">
                            <i className="glyphicon glyphicon-table"></i> Approved applications
                          </Link>
                        </li>
                      </>
                    )}
                    <li>
                      <Box display="flex" alignItems="center">
                        <Link to="/admin/reports/accredited-venues-export">
                          <i className="glyphicon glyphicon-table"></i> Accredited venues export
                        </Link>
                        <DownloadKmz />
                      </Box>
                    </li>
                    <li>
                      <Link to="/admin/reports/walksafe-export">
                        <i className="glyphicon glyphicon-table"></i> WalkSafe export
                      </Link>
                    </li>
                  </ul>
                </div>
                <hr />
              </>
            )}

            {isAdmin && (
              <>
                <div className="alert alert--forms">
                  <h4>Form templates</h4>
                  <ul>
                    <li>
                      <Link to="/premises-info">
                        <i className="glyphicon glyphicon-notes-2"></i> Licensing SAVI premises
                        information
                      </Link>
                    </li>
                    <li>
                      <Link to="/assessment">
                        <i className="glyphicon glyphicon-notes-2"></i> Current assessment
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin/recommendations">
                        <i className="glyphicon glyphicon-pencil"></i> Manage recommendations
                      </Link>
                    </li>
                  </ul>
                </div>
                <hr />

                <div className="alert alert--reports">
                  <h4>Verifiers List Admin</h4>
                  <ul>
                    <li>
                      <Link to="/admin/verifiers/update">
                        <i className="glyphicon glyphicon-pencil"></i> Update verifiers list
                      </Link>
                    </li>
                    <li>
                      <Link to="/admin/verifiers">
                        <i className="glyphicon glyphicon-eye-open"></i> Current verifiers list
                      </Link>
                    </li>
                  </ul>
                </div>
              </>
            )}
          </section>
        </div>
        <div className="col-sm-4 col-xs-12">
          <section>
            <h4 className="like-h3">Resources</h4>
            <ResourcesPanel isAdminView />
          </section>
        </div>
      </div>
    </>
  );
}

