import { OperationVariables, QueryResult } from '@apollo/client';
import { Box, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeItem from '@material-ui/lab/TreeItem';
import { PERMISSIONS } from 'appGlobals';
import { OriginControl } from 'components/Shared/OriginControl';
import React, { useContext } from 'react';
import { GetSiteAndChildrenQuery, GetUsersAdminQuery } from 'tillr-graphql';
import { GROUP_SITE_TYPE, SiteType, VENUE_SITE_TYPE } from 'types';
import UserProfileContext from 'UserProfileContext';
import { SiteIcon } from './SiteIcon';
import { SiteOptions } from './SiteOptions';
import siteStyles from './siteStyles';
import { Users } from './Users';

interface IProps {
  chainId: number;
  siteId: number;
  getSitesState: QueryResult<GetSiteAndChildrenQuery, OperationVariables>;
  getUsersState: QueryResult<GetUsersAdminQuery, OperationVariables>;
  bgColor?: string;
  color?: string;
  name: string;
  siteType: SiteType;
  labelInfo?: string;
  labelText: string;
  children: React.ReactNode;
}

export function SiteTree(props: IProps) {
  const {
    chainId,
    siteId,
    getSitesState,
    getUsersState,
    name,
    siteType,
    labelText,
    labelInfo,
    color,
    bgColor,
    ...other
  } = props;

  const classes = siteStyles();

  const userProfle = useContext(UserProfileContext)!;
  const isAdmin = userProfle.hasAnyPermission([PERMISSIONS.ADMIN]);
  const isVenuesAdmin = userProfle.hasAnyPermission([PERMISSIONS.VENUES_ADMIN]);

  const site = getSitesState.data?.siteAndChildren?.find((x) => x.id === siteId);

  const users =
    getUsersState?.data?.usersAdmin?.filter((x) => x.sites.some((s) => s.id === siteId)) ?? [];
  const invitations =
    getUsersState?.data?.pendingInvitations?.filter((x) => x.sites.some((s) => s.id === siteId)) ??
    [];

  const handleRefetch = () => {
    getSitesState.refetch();
    getUsersState.refetch();
  };

  return (
    <TreeItem
      nodeId={siteId.toString()}
      label={
        <>
          <div className={classes.labelRoot}>
            <div className={classes.labelName}>
              <Box mr={1}>
                <SiteIcon siteType={siteType} />
              </Box>
              <Typography variant="body2" className={classes.labelText}>
                {labelText}
              </Typography>
              {site && siteType === VENUE_SITE_TYPE && (isAdmin || isVenuesAdmin) && (
                <OriginControl
                  siteId={site.id}
                  name={site.name}
                  customData={site.customData}
                  size="small"
                  isEditable={isAdmin}
                />
              )}
            </div>

            <SiteOptions
              chainId={chainId}
              siteId={siteId}
              siteType={siteType}
              sites={getSitesState.data!.siteAndChildren!}
              refetch={handleRefetch}
            />
          </div>
          <Users users={users} invitations={invitations} manage />
        </>
      }
      icon={siteType === GROUP_SITE_TYPE && !other.children && <ExpandMoreIcon />}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
        iconContainer: siteType === VENUE_SITE_TYPE ? classes.venueIconContainer : undefined,
      }}
      {...other}
    />
  );
}

