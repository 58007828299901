import { FormControl } from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import { Origin } from 'types';

interface IProps {
  value: Origin | undefined;
  onChange: (value: Origin) => void;
}

export function UserOriginControl(props: IProps) {
  const { value, onChange } = props;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    onChange(checked ? 'RA' : '');
  };

  return (
    <FormControl>
      <label className="control-label">Is Responsible Authority?</label>
      <div className="checkbox formly-field">
        <label htmlFor="isRA" className="control-label">
          <input
            type="checkbox"
            id="isRA"
            name="primary"
            className="formly-field magic-checkbox"
            checked={value === 'RA'}
            onChange={handleChange}
          />
          <label htmlFor="isRA" />
          Enable "Responsible Authority" mode for this user.
        </label>
      </div>
    </FormControl>
  );
}

