import { Chip } from '@material-ui/core';
import React from 'react';
import { Origin } from 'types';
import { getOriginLabel } from 'utils';

interface IProps {
  origin: Origin;
  showNone?: boolean;
  size?: 'small' | 'medium';
}

export function OriginDisplay(props: IProps) {
  const { origin, showNone = false, size } = props;

  if (!origin && !showNone) {
    return null;
  }

  return <Chip label={getOriginLabel(origin)} color="default" size={size} />;
}

