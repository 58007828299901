import { FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@material-ui/core';
import React from 'react';
import { Origin } from 'types';
import { getOriginLabel } from 'utils';

interface IProps {
  value: Origin | undefined;
  onChange: (value: Origin) => void;
}

export function SiteOriginControl(props: IProps) {
  const { value, onChange } = props;

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    // This is needed for usage inside SiteOptions
    event.stopPropagation();

    onChange(event.target.value as Origin);
  };

  const options: Array<Origin> = ['', 'partners', 'westyorkshire'];

  return (
    <FormControl variant="outlined">
      <InputLabel shrink id="siteOriginControl">
        Origin
      </InputLabel>
      <Select
        label="Origin"
        labelId="siteOriginControl"
        input={<OutlinedInput notched label="Origin" />}
        onChange={handleChange}
        value={value}
        displayEmpty
        fullWidth
      >
        {options.map((value) => (
          <MenuItem key={value} value={value}>
            {getOriginLabel(value)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

