import { Box, Chip, Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useUpdateSiteCustomData } from 'apollo-hooks';
import ErrorDisplay from 'components/ErrorDisplay';
import React, { useState } from 'react';
import { UpdateSiteCustomDataMutationVariables } from 'tillr-graphql';
import { Origin } from 'types';
import { getOriginLabel } from 'utils';
import { CancelButton } from './CancelButton';
import { FormsButtons } from './FormsButtons';
import { ProgressButton } from './ProgressButton';
import { SiteOriginControl } from './SiteOriginControl';

interface IProps {
  siteId: number;
  name: string;
  customData: string | null | undefined;
  size?: 'small' | 'medium';
  isEditable?: boolean;
}

export function OriginControl(props: IProps) {
  const { siteId, name, customData, size, isEditable } = props;

  const [open, setOpen] = useState(false);

  const [displayOrigin, setDisplayOrigin] = useState<Origin>(
    customData ? JSON.parse(customData)?.origin : '',
  );

  const [updateOrigin, setUpdateOrigin] = useState<Origin>(displayOrigin);

  const [updateSiteSettings, updateSiteSettingsState] = useUpdateSiteCustomData();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = () => {
    const variables: UpdateSiteCustomDataMutationVariables = {
      site: {
        siteId,
        customData: JSON.stringify({ origin: updateOrigin }),
      },
    };
    updateSiteSettings({ variables }).then(() => {
      updateSiteSettingsState.reset();
      setDisplayOrigin(updateOrigin);
      setOpen(false);
    });
  };

  const handleChangeOrigin = (value: Origin) => {
    setUpdateOrigin(value);
  };

  return (
    <>
      <Chip
        label={getOriginLabel(displayOrigin)}
        color="default"
        variant="outlined"
        size={size}
        clickable={isEditable}
        onClick={isEditable ? handleOpen : undefined}
        onDelete={isEditable ? handleOpen : undefined}
        deleteIcon={isEditable ? <EditIcon /> : undefined}
      />

      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="xs">
        <DialogTitle>Update origin for {name}</DialogTitle>
        <DialogContent>
          {updateSiteSettingsState.error && <ErrorDisplay error={updateSiteSettingsState.error} />}
          <Box mb={2}>
            <SiteOriginControl value={updateOrigin} onChange={handleChangeOrigin} />
          </Box>
          <FormsButtons>
            <CancelButton onClick={() => setOpen(false)} />
            <ProgressButton
              label="Update"
              loading={updateSiteSettingsState.loading}
              onClick={handleSubmit}
            />
          </FormsButtons>
        </DialogContent>
      </Dialog>
    </>
  );
}

