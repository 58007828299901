import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import { useGetMySites } from 'apollo-hooks';
import { ActivityIndicator } from 'components/ActivityIndicator';
import React, { useContext, useEffect, useState } from 'react';
import { SiteItemFragment } from 'tillr-graphql';
import { VENUE_SITE_TYPE } from 'types';
import UserProfileContext from 'UserProfileContext';
import SiteContext from './SiteContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
      '&:hover > $content': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:focus > $content, &$selected > $content': {
        backgroundColor: '#fcefe3',
        color: theme.palette.primary.main,
      },
      '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
        backgroundColor: 'transparent',
      },
    },
    content: {
      marginBottom: 2,
      color: theme.palette.text.secondary,
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      '$expanded > &': {
        fontWeight: theme.typography.fontWeightRegular,
      },
    },
    group: {
      marginLeft: 7,
      paddingLeft: 18,
      borderLeft: `1px dashed ${theme.palette.text.primary}`,
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: 'inherit',
      color: '#000',
      '& .MuiTypography-body2': {
        fontSize: '1rem',
        color: theme.palette.text.primary,
      },
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0.5, 0),
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
    },
  }),
);

interface StyledTreeItemProps {
  labelText: string;
  nodeId: string;
  type: string | null | undefined;
  children: React.ReactNode;
}

interface IProps {
  showName?: boolean;
}

export default function SiteControl(props: IProps) {
  const { showName = false } = props;

  const classes = useStyles();
  const userProfile = useContext(UserProfileContext);
  const { siteId, setSiteId } = useContext(SiteContext)!;
  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState<string[]>([]);
  const { loading, error, data } = useGetMySites();

  useEffect(() => {
    let expandedArray: string[] = [];
    if (data?.mySites) {
      expandedArray = data?.mySites.map((site) => site.id.toString());
    }
    setExpanded(expandedArray);
  }, [data, setExpanded]);

  if (!userProfile || userProfile.siteIds.length <= 1) {
    return null;
  }

  const handleSiteClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const target = (event.target as Element).closest('li.MuiTreeItem-root');
    if (target && (target as HTMLElement)?.dataset?.siteId) {
      const id = (target as HTMLElement)?.dataset?.siteId;
      setSiteId(Number(id));
    }
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function StyledTreeItem(props: StyledTreeItemProps) {
    const { labelText, nodeId, type, ...other } = props;

    return (
      <TreeItem
        nodeId={nodeId}
        label={
          <div className={classes.labelRoot}>
            <Typography variant="body2" className={classes.labelText}>
              {labelText}
            </Typography>
          </div>
        }
        data-site-id={nodeId}
        onLabelClick={type === VENUE_SITE_TYPE ? handleSiteClick : undefined}
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          selected: classes.selected,
          group: classes.group,
          label: classes.label,
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...other}
      />
    );
  }

  const renderTree = (sites: SiteItemFragment[], parentSiteId: number | null) => {
    let childSites = parentSiteId
      ? sites.filter((x) => x.parentSiteId === parentSiteId)
      : // All sites that don't have a parent also in the list
        sites.filter((x) => !sites.map((y) => y.id).includes(x.parentSiteId || 0));
    childSites = childSites.sort((a, b) => (a.name > b.name ? 1 : -1));
    if (childSites.length > 0) {
      return (
        <>
          {childSites.map((x) => (
            <StyledTreeItem key={x.id} nodeId={x.id.toString()} type={x.type} labelText={x.name}>
              {renderTree(sites, x.id)}
            </StyledTreeItem>
          ))}
        </>
      );
    }
    return null;
  };

  const currentSite = data?.mySites ? data.mySites.find((x) => x.id === siteId) : null;

  return (
    <>
      {loading && <ActivityIndicator size="1rem" color="white" />}
      {error && <span>⚠️ Error loading venues ⚠️</span>}
      {data?.mySites && currentSite && (
        <>
          {showName && <>{currentSite.name} </>}
          <button type="button" className="link-button" onClick={handleOpen}>
            {showName ? '(switch)' : 'switch'}
          </button>
          <Dialog
            open={open}
            onClose={handleClose}
            scroll="paper"
            aria-labelledby="switch-venue-dialog-title"
            aria-describedby="switch-venue-dialog-description"
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle
              id="switch-venue-dialog-title"
              style={{ backgroundColor: '#00a0e4', color: 'white' }}
            >
              Switch venue
            </DialogTitle>
            <DialogContent dividers>
              <TreeView
                className={classes.root}
                expanded={expanded}
                defaultCollapseIcon={<ExpandMoreIcon />}
              >
                {renderTree(data.mySites, null)}
              </TreeView>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  );
}
