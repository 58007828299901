import { VENUES_STATUS_REPORT_ID } from 'appGlobals';
import { useEffect } from 'react';
import { Origin } from 'types';
import { IRunReportToJsonState, useRunReportToJson } from './Reports/useRunReportToJson';
import { ReviewStatus } from './useGetReviewsData';

export interface IVenueStatusData {
  SiteId: number;
  SiteName: string;
  Origin: Origin;
  PremisesInfoFormId: string;
  EndUserAgreementFormId: string;
  PaymentDateTime: Date | null;
  AssessmentFormId: string;
  AssessmentStartedDateTime: Date | null;
  PercentageComplete: number;
  Score: number | null;
  IsCritical: boolean;
  StarRating: number | null;
  AssessmentCompletedDateTime: Date | null;
  AssessmentSubmittedDateTime: Date | null;
  CurrentReviewStatus: ReviewStatus | '';
  AssessmentApprovedDateTime: Date | null;
  CanRenewDateTime: Date | null;
  AccreditationExpiryDateTime: Date | null;
}

export type GetVenueStatusesState = IRunReportToJsonState<IVenueStatusData> & {
  fetchData: (siteId: number) => void;
  currentStatus: IVenueStatusData | undefined;
  getStatusesOfSite: (siteId: number) => Array<IVenueStatusData> | undefined;
  getCurrentStatusOfSite: (siteId: number) => IVenueStatusData | undefined;
};

export function useGetVenueStatuses(siteId?: number) {
  const { runReport, runReportState } =
    useRunReportToJson<IVenueStatusData>(VENUES_STATUS_REPORT_ID);

  const fetchData = (_siteId: number) => runReport({ siteId: _siteId.toString() });

  useEffect(() => {
    if (siteId) {
      fetchData(siteId);
    }
  }, [siteId]);

  const getStatusesOfSite = (_siteId: number): Array<IVenueStatusData> | undefined => {
    return runReportState?.data?.filter((x) => x.SiteId === _siteId);
  };

  const getCurrentStatusOfSite = (_siteId: number): IVenueStatusData | undefined => {
    const statuses = getStatusesOfSite(_siteId);
    // Most recent record is last
    return statuses ? statuses[statuses.length - 1] : undefined;
  };

  const currentStatus = siteId ? getCurrentStatusOfSite(siteId) : undefined;

  return { ...runReportState, fetchData, currentStatus, getStatusesOfSite, getCurrentStatusOfSite };
}

