import { Button, Dialog, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { useUpdateUser } from 'apollo-hooks';
import { MODULE_ID } from 'appGlobals';
import ErrorDisplay from 'components/ErrorDisplay';
import { CancelButton } from 'components/Shared/CancelButton';
import { FormsButtons } from 'components/Shared/FormsButtons';
import { ProgressButton } from 'components/Shared/ProgressButton';
import React, { useState } from 'react';
import { SiteUserAdminItemFragment, UpdateUserMutationVariables } from 'tillr-graphql';

interface IProps {
  siteId: number;
  user: SiteUserAdminItemFragment;
}

export function RemoveUser(props: IProps) {
  const { siteId, user } = props;

  const [open, setOpen] = useState(false);

  const [updateUser, updateUserState] = useUpdateUser({
    getSiteAdminQueryVariables: { id: siteId },
  });

  const handleSubmit = () => {
    const variables: UpdateUserMutationVariables = {
      user: {
        userId: user.id,
        roleId: user.role.id,
        modules: [MODULE_ID],
        siteIds: user.sites.map((x) => x.id).filter((x) => x !== siteId),
      },
    };

    updateUser({ variables }).then((response) => {
      if (response.data?.updateUser) {
        updateUserState.reset();
        setOpen(false);
      }
    });
  };

  return (
    <>
      <Button variant="outlined" size="small" onClick={() => setOpen(true)}>
        Remove user
      </Button>

      <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="xs">
        <DialogTitle>Remove user</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to remove <b>{user.name}</b> from this venue?
          </Typography>

          {updateUserState.error && <ErrorDisplay error={updateUserState.error} />}

          <FormsButtons>
            <CancelButton onClick={() => setOpen(false)} />
            <ProgressButton
              label="Yes, remove"
              loading={updateUserState.loading}
              onClick={handleSubmit}
            />
          </FormsButtons>
        </DialogContent>
      </Dialog>
    </>
  );
}
