import { useCreateForm, useGetSite, useUpdateForm, useUpdateSite } from 'apollo-hooks';
import {
  MODULE_ID,
  PERMISSIONS,
  PREMISES_INFO_CHAIN_NAME_FIELD_ID,
  PREMISES_INFO_FIRST_NAME_FIELD_ID,
  PREMISES_INFO_IS_CHAIN_FIELD_ID,
  PREMISES_INFO_LAST_NAME_FIELD_ID,
  PREMISES_INFO_LAT_FIELD_ID,
  PREMISES_INFO_LONG_FIELD_ID,
  PREMISES_INFO_POSTCODE_FIELD_ID,
  PREMISES_INFO_PREMISES_NAME_FIELD_ID,
  PREMISES_INFO_TEMPLATE_ID,
} from 'appGlobals';
import { ActivityIndicator } from 'components/ActivityIndicator';
import ErrorDisplay from 'components/ErrorDisplay';
import { ReturnLink } from 'components/Shared/ReturnLink';
import { TillrModal } from 'components/Shared/TillrModal';
import SiteContext from 'components/Sites/SiteContext';
import FormlyForm from 'formly/FormlyForm';
import { IModel } from 'formly/IModel';
import { useGetLatestFormAndTemplate } from 'hooks/useGetLatestFormAndTemplate';
import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router';
import { UpdateSiteMutationVariables } from 'tillr-graphql';
import { VENUE_SITE_TYPE } from 'types';
import UserProfileContext from 'UserProfileContext';
import { generateUuid } from 'utils';

export function PremisesInfoPage() {
  const { search } = useLocation();
  const history = useHistory();

  const editable = new URLSearchParams(search).has('editable');

  const { siteId } = useContext(SiteContext)!;
  const module = MODULE_ID;

  const { loading, templateName, formlyConfig, form, formData, error, refetch } =
    useGetLatestFormAndTemplate(PREMISES_INFO_TEMPLATE_ID);

  const getSiteState = useGetSite({ id: siteId });

  const [createForm, createFormState] = useCreateForm();
  const [updateForm, updateFormState] = useUpdateForm({ siteId, module, id: form?.id });

  const [updateSite, updateSiteState] = useUpdateSite();

  const userProfile = useContext(UserProfileContext)!;
  const isReviewer = userProfile.hasAnyPermission([PERMISSIONS.REVIEWER, PERMISSIONS.ADMIN]);
  const isUpdate = form?.id;
  const mode = isReviewer ? 'readonly' : isUpdate ? 'manage' : 'create';

  const site = getSiteState.data?.site;

  const handleClose = () => {
    history.push('/journey');
  };

  const handleSubmit = (submittedModel: IModel) => {
    if (isUpdate) {
      updateForm({
        variables: {
          siteId,
          module,
          form: {
            id: form.id,
            name: templateName,
            formDataJson: JSON.stringify(submittedModel),
            isSubmitted: false,
          },
        },
      });
    } else {
      createForm({
        variables: {
          siteId,
          module,
          form: {
            id: generateUuid(),
            templateId: PREMISES_INFO_TEMPLATE_ID,
            name: templateName,
            formDataJson: JSON.stringify(submittedModel),
            isSubmitted: false,
          },
        },
      }).then(() => {
        refetch();
      });
    }

    const name = submittedModel[PREMISES_INFO_PREMISES_NAME_FIELD_ID];
    const postcode = submittedModel[PREMISES_INFO_POSTCODE_FIELD_ID];
    const siteName = `${name} (${postcode})`;
    // Only update if site is not part of a chain (no parent)
    if (site && site.name !== siteName && !site.parentSiteId) {
      const variables: UpdateSiteMutationVariables = {
        site: {
          id: siteId,
          name: siteName,
          type: VENUE_SITE_TYPE,
        },
      };
      updateSite({ variables });
    }
  };

  const savedSuccessfully =
    (createFormState.data?.createForm || updateFormState.data?.updateForm) &&
    (!updateSiteState.called || updateSiteState.data);

  return (
    <>
      <div className="assessment">
        {savedSuccessfully && (
          <TillrModal title="Thank you" okButtonText="Return to your journey" onOk={handleClose}>
            Your details have been saved.
          </TillrModal>
        )}
        <section className="triage">
          <h3>
            Premises Information <ReturnLink />
          </h3>

          {(loading || getSiteState.loading) && (
            <div className="assessment-loader">
              <p>Retrieving the form...</p>
              <ActivityIndicator showProgressBar />
            </div>
          )}

          {error && <ErrorDisplay error={error} />}

          {formlyConfig && !getSiteState.loading && (
            <div>
              <div className="alert alert--info alert--info-small">
                <p>
                  You must complete the information below before beginning your assessment. Once
                  completed, you will be able to view and update your details at a later date by
                  following the link from the journey.
                </p>
              </div>

              <FormlyForm
                model={
                  formData ?? {
                    [PREMISES_INFO_FIRST_NAME_FIELD_ID]: userProfile?.firstName,
                    [PREMISES_INFO_LAST_NAME_FIELD_ID]: userProfile?.lastName,
                    [PREMISES_INFO_PREMISES_NAME_FIELD_ID]:
                      site?.type === VENUE_SITE_TYPE ? site?.name : undefined,
                    [PREMISES_INFO_IS_CHAIN_FIELD_ID]: site?.topParentSite ? 'Yes' : undefined,
                    [PREMISES_INFO_CHAIN_NAME_FIELD_ID]: site?.topParentSite?.name,
                  }
                }
                config={formlyConfig}
                mode={mode}
                onCancel={handleClose}
                onSubmit={handleSubmit}
                readonlyKeys={
                  isUpdate && !editable
                    ? [
                        'f40cdce9-fe42-4e2f-829a-199b5e44c6ce',
                        '2c64012c-e51b-469f-a96e-26547f340648',
                        '8129b632-50ac-4eb3-8f28-5bbf50d80fc9',
                        'b189408c-aee4-44c0-aee8-f8bf83666581',
                        'e1ea30a0-38d7-4b21-8da8-43fe7489eac9',
                        'ee08cc04-c721-4a9a-9843-93fa3019db61',
                      ]
                    : []
                }
                hiddenFieldKeys={[
                  PREMISES_INFO_LAT_FIELD_ID,
                  PREMISES_INFO_LONG_FIELD_ID,
                  '1ee66ec0-e7b2-4bbc-a3e4-000000000000',
                ]}
                extraRequiredKeys={[
                  'ee08cc04-c721-4a9a-9843-93fa3019db61',
                  '2c64012c-e51b-469f-a96e-26547f340648',
                ]}
                submitting={
                  createFormState.loading || updateFormState.loading || updateSiteState.loading
                }
              />
            </div>
          )}
        </section>
      </div>
    </>
  );
}

