import React, { PropsWithChildren, useContext, useEffect, useState } from 'react';
import UserProfileContext from 'UserProfileContext';
import SiteContext from './SiteContext';

const SITE_ID_KEY = 'LSAVI:SITE_ID';

export default function SiteManager(props: PropsWithChildren<{}>) {
  const { children } = props;

  const [siteId, setSiteId] = useState(0);

  const userProfile = useContext(UserProfileContext);

  useEffect(() => {
    if (userProfile) {
      const siteIdFromStorage = Number(localStorage.getItem(SITE_ID_KEY));
      if (userProfile.siteIds.includes(siteIdFromStorage)) {
        setSiteId(siteIdFromStorage);
      } else {
        const nextSiteId = userProfile.siteIds[0];
        localStorage.setItem(SITE_ID_KEY, nextSiteId.toString());
        setSiteId(nextSiteId);
      }
    }
  }, [userProfile]);

  const handleChange = (nextSiteId: number) => {
    setSiteId(nextSiteId);
    localStorage.setItem(SITE_ID_KEY, nextSiteId.toString());
  };

  return (
    <SiteContext.Provider value={{ siteId, setSiteId: handleChange }}>
      {children}
    </SiteContext.Provider>
  );
}
